
/* General Style Rules */

a {
  text-decoration: none;
  cursor: pointer;
}

.modal-content {
  border-radius: 15px !important;
  border: none !important;
}

.checkout-image {
  max-height: 200px;
}

.btn-success {
  color: #fff;
  background-color: #27ae60;
  border-color: #27ae60;
}

.row-flex {
  display: flex !important;
  flex-wrap: wrap !important;
}

/* Dashboard Menu Styling */

a.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  background: #f8f9fa;
  border: none;
  padding: 15px;
}

.dropdown-menu>li>a {
  border-radius: 5px;
  margin-bottom: 5px;
}

.dropdown-menu>li>a:hover {
  color: white;
  background-color: #212529;
}
.dropdown.open .dropdown-toggle {
  color: black;
}

.dropdown-item.active, .dropdown-item:active {
  background: #212529;
}


